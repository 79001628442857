import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { config } from '../../config';
// import solIcon from "../assets/SOLIcon.png";
import tgIcon from '../assets/TGIcon.png';
import twIcon from '../assets/TWIcon.png';
import bookIcon from '../assets/BookIcon.png';

export default function Footer() {
  return (
    <div>
      <Grid container justifyContent="center" spacing={3} marginTop={4}>
        <Grid item>
          <a href="https://twitter.com/BakedBeansSol" target="__blank">
            <img src={twIcon} alt="" width={44} height={44} />
          </a>
        </Grid>
        <Grid item>
          <a href="https://t.me/+NPbj2SmYPYxlNDk0" target="__blank">
            <img src={tgIcon} alt="" width={46} height={46} />
          </a>
        </Grid>
        <Grid item>
          <a href="https://baked-beans-solana.gitbook.io/baked-beans-solana-miner-documentation-outline/" target="__blank">
            <img src={bookIcon} alt="" width={46} height={46} />
          </a>
        </Grid>
      </Grid>
      <center>
        <Typography variant="h8" marginTop={4}>
          <br />
          Copyright@BakedBeans 2024
        </Typography>
      </center>
    </div>
  );
}
