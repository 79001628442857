import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../index.css';

function HorizontalImageSlide({ imgList }) {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    beforeChange: function (currentSlide, nextSlide) {
      console.log('before change', currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log('after change', currentSlide);
    },
  };

  return (
    <div className="horizontal-slider-container">
      <Slider {...settings}>
        {imgList.map((item, index) => {
          return (
            <a
              key={index}
              className="horizontal-carousel-item"
              href={item.url}
              target="_blank"
            >
              <img
                src={item.src}
                alt=""
                style={{ width: '160px', height: '160px' }}
              />
            </a>
          );
        })}
      </Slider>
    </div>
  );
}

export default HorizontalImageSlide;
