import { flexbox, styled } from '@mui/system';
import { useWallet } from '@solana/wallet-adapter-react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import {
  WalletDialogProvider as MaterialUIWalletDialogProvider,
  WalletMultiButton as MaterialUIWalletMultiButton,
  WalletConnectButton,
} from '@solana/wallet-adapter-material-ui';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Header from './components/Header';
import BakeCard from './components/BakeCard';
import NutritionFacts from './components/NutritionFacts';
import ReferralLink from './components/ReferralLink';
import Footer from './components/Footer';

import '../index.css';
import SolanaHeartImg from './assets/solana-heart.svg';
import CrayonHeartImg from './assets/crayon-heart.svg';
import logo from '../assets/FifthLogoTransparent.png';
import ImgCarousel from './components/ImgCarousel';
import HorizontalImageSlide from './components/HorizontalImageSlide';

const AdsWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  maxWidth: 300,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  backgroundColor: '#fffcd7',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  maxWidth: 500,
  margin: '0 auto',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

const WalletButton = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  marginBottom: '20px',
  // flexDirection: 'row-reverse',
}));

const Header = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginLeft: '15%',
  marginRight: '15%',
  [theme.breakpoints.down('md')]: {
    h5: {
      fontSize: 20,
      margin: 0,
    },
  },
  '@media only screen and (max-width: 600px)': {
    margin: '5% !important',
  },
}));

export default function Home() {
  //const { address } = useAuthContext();
  const wallet = useWallet();

  const imgList1 = [
    {
      src: 'slide/img1.png',
      url: '#',
    },
    {
      src: 'slide/img2.png',
      url: '#',
    },
    {
      src: 'slide/img1.png',
      url: '#',
    },
    {
      src: 'slide/img2.png',
      url: '#',
    },
  ];

  const imgList2 = [
    {
      src: 'slide/img1.png',
      url: '#',
    },
    {
      src: 'slide/img2.png',
      url: '#',
    },
    {
      src: 'slide/img1.png',
      url: '#',
    },
    {
      src: 'slide/img2.png',
      url: '#',
    },
  ];

  const imgList = [...imgList1, ...imgList2];

  return (
    <div className="main-container">
      <div className="carousel-container" style={{ left: 0 }}>
        <ImgCarousel imgList={imgList1} />
      </div>
      <Wrapper>
        <Header>
          <img src={logo} className="logo" alt="" />
          <WalletButton>
            <MaterialUIWalletMultiButton
              variant="text"
              label="CONNECT"
              style={{
                fontSize: '18px',
                padding: '12px',
                color: '#1b1b1c',
                background: '#f4b42c',
                borderRadius: '10px',
                width: '100%',
                boxShadow: '4px 3px 28px',
              }}
            ></MaterialUIWalletMultiButton>
          </WalletButton>
          <p className="description">
            The Classic Rewards Pool with the Tastiest
            <br />
            and most Sustainable Daily Rewards
          </p>
        </Header>
        <div className="horizontal-carousel-container">
          <HorizontalImageSlide imgList={imgList} />
        </div>
        <BakeCard />
        <NutritionFacts />
        <ReferralLink
          address={wallet.publicKey && wallet.publicKey.toBase58()}
        />
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Wrapper>
      <div className="carousel-container" style={{ right: 0 }}>
        <ImgCarousel imgList={imgList2} />
      </div>
    </div>
  );
}
