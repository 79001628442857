export enum NETWORK {
  MAINNET = 0,
  DEVNET = 1,
}

export const ACTIVE_NETWORK : NETWORK = NETWORK.MAINNET;

export const config = {
  scanLink:
    ACTIVE_NETWORK === NETWORK.DEVNET ? 
    "https://www.bscscan.com/address/0xE2D26507981A4dAaaA8040bae1846C14E0Fb56bF?cluster=devnet" :
    "https://www.bscscan.com/address/0xE2D26507981A4dAaaA8040bae1846C14E0Fb56bF",
};
