import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/system';

const CardWrapper = styled(Card)({
  background: 'rgb(255 252 248)',
  marginBottom: 24,
  border: '1px solid #555',
});

const nutritionFacts = [
  {
    label: 'Daily Return',
    value: 4.9,
  },
  {
    label: 'APR',
    value: '1,788.5',
  },
  {
    label: 'Marketing Fee',
    value: 7.4,
  },
];

export default function NutritionFacts() {
  return (
    <CardWrapper
      style={{
        color: '#1b1b1c',
        background: '#fffcf7',
        boxShadow: '4px 3px 28px',
      }}
    >
      <CardContent className="fact">
        <Typography
          variant="h5"
          sx={{ color: '#15205e', borderBottom: '4px solid #15205e' }}
        >
          Nutrition Facts
        </Typography>

        <Box paddingTop={2}>
          {nutritionFacts.map((f) => (
            <Grid container key={f.label} justifyContent="space-between">
              <Typography
                sx={{ color: '#15205e' }}
                variant="body1"
                gutterBottom
              >
                {f.label}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexGrow: 1,
                }}
              >
                {/* <div
                  style={{
                    width: '100%',
                    height: '1px',
                    borderTop: '1px dotted black',
                  }}
                ></div> */}
              </div>
              <Typography sx={{ color: 'black' }} gutterBottom>
                {f.value}%
              </Typography>
            </Grid>
          ))}
        </Box>
      </CardContent>
    </CardWrapper>
  );
}
